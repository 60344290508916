@import './colors.css';

.MuiTabs-indicator {
  background-color: var(--color-orange);
}

.MuiTab-wrapper {
  font-family: Rubik;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: none;
}

.MuiTab-textColorPrimary {
  color: var(--color-black);
}

.MuiTab-textColorPrimary.Mui-selected {
  color: var(--color-orange);
}

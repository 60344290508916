@import './colors.css';

$break-large: 1200px;

/* Modal's background */
.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.9);
}

.SuccessModal {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  @media screen and (min-width: $break-large) {
    min-width: 610px;
    width: 100%;
  }
  img {
    max-width: 350px;
  }
  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .description {
    margin: 15px;
  }
  button {
    margin-bottom: 30px;
  }
}

.CancelModal {
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (min-width: $break-large) {
    min-width: 610px;
    width: 100%;
  }
  img {
    max-width: 350px;
  }
  h2 {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 33px;
    text-align: center;
    margin-top: 0px;
  }
  .description {
    margin: 15px;
  }
  button {
    margin-bottom: 30px;
  }
}
